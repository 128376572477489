import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import RuledBreak from "../../components/RuledBreak"

const About = ({ data }) => {
  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  // about img
  const aboutCali = data.aboutCali.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={headerImg}
      alt={"Cali Reeves in the flowers"}
      height={400}
      mobile={300}
    >
      <SEO title="About" keywords={["Eyelashes", "Cali Reeves", "About"]} />
      <div className="row mt-5">
        <div className="col-md-12">
          <RuledBreak>About</RuledBreak>
        </div>
      </div>
      {/* ----------------------------------------------------------------------- */}
      <div className="row my-4">
        <div className="col-md-6 d-flex flex-column align-items-center">
          <h4 className="py-3 px-2">
            <span className="bebas">Meet Cali Reeves</span> - 22 year old lash
            artist and self made business professional of five years. After
            completing other volume lash courses, she felt something was missing
            from the lash education... a step by step explanation to time
            efficient and symmetrical A.F. (As F*ck) volume fans. Thus, Fan Like
            A Pro was born into existence.
          </h4>
          <h4 className="py-3 px-2">
            <span className="bebas">Fan Like a Pro</span> is a trilogy of
            educational content for lash artists counting it’s online
            membership, the advanced in-person two-day training experience, and
            individual instructional online video courses.
          </h4>
        </div>
        <div className="col-md-6">
          <Img
            fluid={aboutCali}
            alt={"About Cali Reeves - Fan Like A Pro"}
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query aboutQuery {
    header: allFile(filter: { name: { eq: "about_header_1200x671" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
    aboutCali: allFile(filter: { name: { eq: "cali_about_600x600" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`
export default About
